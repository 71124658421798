.confirmation_dialogue_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.confirmation_dialogue_container .actions button {
    min-width: 150px;
    flex: 1;
}

.order_form {
    width: 70%;
}

@media screen and (max-width: 600px) {
    .order_form {
        width: 100%;
    }

    .confirmation_dialogue_container {
        display: unset;
    }
}