.box {
    border: 1px solid !important;
    color: #fff;
    position: relative;
    cursor: pointer;
}

.box:before {
    position: absolute;
    width: 0;
    height: 1px;
    content: "";
    background: #fff;
    top: -1px;
    left: -1px;
    transition: 4s width;
    -web-kit-transition: 4s width;
}

.box:after {
    position: absolute;
    width: 2px;
    height: 0;
    content: "";
    background: #fff;
    top: -1px;
    right: -1px;
    transition: 4s height;
    -web-kit-transition: 4s height;
}

.box-inner:after {
    position: absolute;
    width: 0;
    height: 1px;
    content: "";
    background: #fff;
    bottom: -1px;
    right: -1px;
    transition: 4s width;
    -web-kit-transition: 4s;
}

.box-inner:before {
    position: absolute;
    width: 2px;
    height: 0;
    content: "";
    background: #fff;
    bottom: -1px;
    left: -1px;
    transition: 4s height;
    -web-kit-transition: 4s height;
}

.box.start {
    border-color: #999999 !important;
}

.box.start:before {
    width: calc(100% + 2px);
    transition: 4s width linear;
    -web-kit-transition: 4s width linear;
}

.box.start:after {
    height: calc(100% + 2px);
    transition: 4s height 4s linear;
    -web-kit-transition: 4s height 4s linear;
}

.box.start .box-inner:after {
    width: calc(100% + 2px);
    transition: 4s width 8s linear;
    -web-kit-transition: 4s width 8s linear;
}

.box.start .box-inner:before {
    height: calc(100% + 2px);
    transition: 3s height 12s linear;
    -web-kit-transition: 3s height 12s linear;
}