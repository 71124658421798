.login-container {
    width: 400px;
    padding: 40px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #CCCCCC;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media screen and (max-width:600px) {
    .login-container {
        max-width: 95%;
        padding: 20px;
    }
}