.paginate_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 22px 0 5px;
}

.paginate_info,
.paginate_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
}

.paginate_buttons button {
    padding: 0;
    background-color: transparent;
    color: #aaa;
    min-width: 35px;
}

.paginate_buttons button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.paginate_buttons button:hover,
.paginate_buttons button:active,
.paginate_buttons button:focus {
    background: transparent;
    border: none;
    box-shadow: none;
}

.paginate_buttons button.active {
    color: #000;
    font-weight: 500;
}

.select_pagesize span {
    color: #aaa;
    font-size: 0.875rem;
}

@media screen and (max-width:600px) {
    .paginate_container {
        flex-direction: column-reverse;
    }

    .paginate_info {
        flex-direction: column;
    }

    .paginate_buttons {
        margin: 12px 0;
    }
}