.mask-container {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    outline: none;
}

@media screen and (max-width: 768px) {
    .mask-container {
        max-width: 90%;
    }
}