@font-face {
  font-family: "FilsonPro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/FilsonPro-Medium.eot") format("eot"),
    url("./assets/fonts/FilsonPro-Medium.ttf") format("truetype"),
    url("./assets/fonts/FilsonPro-Medium.woff") format("woff");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "FilsonPro", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

body,
html {
  font-family: "FilsonPro", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  text-transform: none !important;
}