.order_item_row.canceled {
    background-color: #f7f7f7;
}

.order_item_row.canceled td {
    color: #ccc;
}

.order_item_row td {
    color: #333;
}

.order_item_row .bold_state {
    color: #FAA51A;
    font-weight: 500;
}

.added {
    animation: shimmer 1s cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

@keyframes shimmer {
    0% {
        background-color: rgba(250, 200, 134, 0.1);
    }

    40% {
        background-color: rgba(250, 200, 134, 0.2);
    }

    80% {
        background-color: rgba(250, 200, 134, 0.09);
    }

    100% {
        background-color: #fff;
    }
}