:root {
    --sideber-width: 250px;
    --navbar-height: 55px
}

.dashboard-layout {
    height: 100vh;
    position: relative;
    background-color: #eee;
    overflow-y: hidden;
}

.dashboard-layout .navbar {
    height: var(--navbar-height);
    background-color: #fff;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-layout .sidebar {
    width: var(--sideber-width);
    margin-top: var(--navbar-height);
    background-color: #000000;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 0.1s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1;
}

.dashboard-layout .main-content {
    height: calc(100vh - var(--navbar-height));
    width: calc(100% - var(--sideber-width));
    overflow-y: auto;
    margin-left: var(--sideber-width);
    padding: 16px 20px;
    transition: all 0.1s;
}

.dashboard-layout .main-content.extended {
    width: 100%;
    margin-left: 0;
}

.dashboard-layout .sidebar.close {
    width: 0;
}

.dashboard-layout .sidebar .close-siderbar {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #fff;
}

.dashboard-layout .sidebar-item {
    padding: 0 10px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}


.dashboard-layout .sidebar-item a {
    color: #E6E6E6;
    overflow: hidden;
    white-space: nowrap;
    padding: 12px 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.dashboard-layout .sidebar-item a .icon {
    color: #E6E6E6;
    margin-right: 7px;
}

.dashboard-layout .sidebar-item a.active .icon {
    color: #fff;
}

.dashboard-layout .sidebar-item a.active {
    background-color: #333333;
    color: #fff;
    border-radius: 10px;
}

.dashboard-layout .signout-wrapper {
    position: absolute;
    bottom: 30px;
    left: 20px;
    color: #fff;
    cursor: pointer;
}

.dashboard-layout .avatar-wrapper .desk-avatar {
    /* background-color: #F5F5F5; */
    border-radius: 6px;
    padding: 8px;
    font-size: 0.85rem;
    font-weight: 500;
}

.dashboard-layout .avatar-wrapper .mob-avatar {
    background-color: #F5F5F5;
    border-radius: 50%;
    padding: 6px;
    position: relative;
    display: block;
}

.dashboard-layout .avatar-wrapper .mob-avatar-dropdown {
    position: absolute;
    top: 42px;
    right: 0;
    background: #fff;
    min-width: 150px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    font-size: 0.875rem;
    z-index: 1;
}

.dashboard-layout .avatar-wrapper .mob-avatar-dropdown .logout {
    color: #808080;
    padding-top: 6px;
    border-top: 1px solid #E6E6E6;
    margin-top: 6px;
}

@media screen and (max-width: 768px) {
    .dashboard-layout .sidebar {
        margin-top: 0;
        border-radius: 0 12px 12px 0;
    }

    .dashboard-layout .main-content {
        width: 100%;
        margin-left: 0;
        padding: 12px;
    }

    .dashboard-layout .avatar-wrapper .desk-avatar {
        display: none;
    }
}